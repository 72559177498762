import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, useTheme, Typography } from '@mui/material';
import { ActionButtons } from './components';
import { ActivitiesCardContext } from '../context/ActivitiesCardContext';

const DeleteEventModal = () => {
  const { t } = useTranslation();
  const {
    isModalDeleteEventOpen,
    closeModalDeleteEvent,
    handleDeleteActivity,
    eventIdToDelete
  } = useContext(ActivitiesCardContext);

  const theme = useTheme();

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '50%',
          //@ts-ignore
          backgroundColor: theme.palette.background.panel,
          backgroundImage: 'unset',
        },
      }}
      maxWidth={false}
      open={isModalDeleteEventOpen}
      onClose={closeModalDeleteEvent}
    >
      <DialogTitle>{t('global.delete')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>

        {t('contactsView.activities.deleteEvent')}
        </Typography>
      </DialogContent>
      <ActionButtons
        closeEventPopUp={closeModalDeleteEvent}
        handleDeleteActivity={handleDeleteActivity}
        eventIdToDelete={eventIdToDelete}
      />
    </Dialog>
  );
};

export default DeleteEventModal;
