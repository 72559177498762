import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
// Icons
import { Handyman } from '@mui/icons-material';
import { Property } from '@trii/types/dist/Contacts';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';
import { useSelector } from 'react-redux';
import { selectCustomContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';

interface Props {
  newProperties: Property[];
  contactId: string;
  setPropertySelected: Dispatch<SetStateAction<boolean>>;
  isMerged?: boolean;
}

const Properties = ({
  newProperties,
  contactId,
  setPropertySelected,
  isMerged = false,
}: Props) => {
  const { t } = useTranslation();

  const {
    properties,
    triggerSelectAllId,
    setTriggerSelectAllId,
    handleSelectProperty,
  } = useContext(DuplicateContext);

  const [propertyList, setPropertyList] = useState<Property[]>([]);
  const [propertiesKeys, setPropertiesKeys] = useState<string[]>([]);
  const [propertyValue, setPropertyValue] = useState<string[]>([]);

  const radiosRef = useRef<(HTMLInputElement | null)[]>([]);

  const customContactFields = useSelector(selectCustomContactFields);

  const getPropertyValue = (nameKey: string) => {
    if (isMerged) {
      return (
        properties?.find((property) => property.nameKey === nameKey)?.value || ''
      );
    }
    return (
      newProperties?.find((property) => property.nameKey === nameKey)?.value || ''
    );
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
    property: TableContactField
  ) => {
    const foundProperty = propertyList.find(
      (prop) => prop.nameKey === property.nameKey
    );

    // @ts-ignore
    setPropertySelected(e.target.checked);
    handleSelectProperty(
      foundProperty || {
        nameKey: property.nameKey,
        type: property.type,
        value: '',
      }
    );
  };

  useEffect(() => {
    if (newProperties && newProperties?.length > 0) {
      setPropertyList(newProperties);
    }
  }, [newProperties]);

  useEffect(() => {
    if (properties && properties?.length > 0) {
      const keys = properties.map((property: Property) => property.nameKey);
      const values = properties.map((property: Property) => property.value);
      setPropertyValue(values);
      setPropertiesKeys(keys);
      if (isMerged) {
        console.log('Properties change: ', properties);

        setPropertyList(properties);
      }
    }
  }, [properties]);

  useEffect(() => {
    if (triggerSelectAllId !== '' && triggerSelectAllId === contactId) {
      setTriggerSelectAllId('');
      // Trigger a click programatically on every radio button here
      radiosRef.current.forEach((radio) => {
        if (radio && !radio.checked) {
          radio.click();
        }
      });
    }
  }, [triggerSelectAllId, contactId, setTriggerSelectAllId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderBottom: '1px solid',
          borderColor: (theme) => theme.palette.divider,
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="semi-bold"
          color={'text.disabled'}
        >
          {t('duplicatesView.contactInfo.properties')}
        </Typography>
      </Box>
      {customContactFields.map((property, i) => (
        <FormControlLabel
          key={i}
          control={
            !isMerged ? (
              <Radio
                size="small"
                checked={
                  propertyValue.includes(getPropertyValue(property.nameKey)) &&
                  propertiesKeys.includes(property.nameKey)
                }
                inputRef={(el) => (radiosRef.current[i] = el)}
              />
            ) : (
              <></>
            )
          }
          onClick={(e) => !isMerged && handleSelect(e, property)}
          label={
            <Box
              sx={{
                width: '240px',
                height: 'max-content',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Handyman
                fontSize="small"
                sx={{
                  color: (theme) => theme.palette.primary.dark,
                }}
              />
              <Typography
                variant="subtitle1"
                fontWeight="semi-bold"
                fontSize={14}
                color={'text.disabled'}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {property.name}:{' '}
                {getPropertyValue(property.nameKey) === ''
                  ? '-'
                  : getPropertyValue(property.nameKey)}
              </Typography>
            </Box>
          }
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        />
      ))}
    </Box>
  );
};

export default Properties;
