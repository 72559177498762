import { useContext, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectContactFields } from 'ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
// Components/ui
import { Box, Fade, Grid } from '@mui/material';
import { ContactCard, JoinButton } from './components';
import { IContact } from '@trii/types/dist/Contacts';
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

interface IDuplicatedContactPanelProps {
  data: { contacts: IContact[]; count: number };
}

const DuplicatedContactPanel = ({ data }: IDuplicatedContactPanelProps) => {
  const { contactList, setContactList } = useContext(DuplicateContext);

  const [show, setShow] = useState<boolean>(false);

  const contactFields = useSelector(selectContactFields);

  const getLength = (contact: IContact[], property: string) => {
    return contact.reduce((acc: number, contact: IContact) => {
      if (
        Object.getPrototypeOf(contact[property]) &&
        contact[property]?.length > acc
      ) {
        return contact[property].length;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (data.contacts.length > 0 && contactFields.length > 0) {
      const nameKeys = contactFields.map((field) => field?.nameKey);
      const newContacts = data.contacts.map((contact) => {
        const properties = contact.properties?.filter(
          (property) => nameKeys.includes(property?.nameKey) && property?.value
        );
        return { ...contact, properties: properties };
      });
      const maxPhoneLength = getLength(newContacts, 'phones');
      const maxEmailLength = getLength(newContacts, 'emails');
      const maxImLengthFacebook = getLength(newContacts, 'ims_facebook');
      const maxImLengthInstagram = getLength(newContacts, 'ims_instagram');
      const maxImLengthWhatsapp = getLength(newContacts, 'ims_whatsapp');
      // const maxPropertyLength = getLength(newContacts, 'properties');
      const newList = newContacts.reduce((acc: IContact[], contact: IContact) => {
        let newContact = contact;
        if (contact?.phones?.length < maxPhoneLength) {
          newContact = {
            ...newContact,
            phones: [
              ...newContact?.phones,
              ...Array(maxPhoneLength - contact?.phones?.length).fill({}),
            ],
          };
        }
        if (contact?.emails?.length < maxEmailLength) {
          newContact = {
            ...newContact,
            emails: [
              ...newContact?.emails,
              ...Array(maxEmailLength - contact?.emails?.length).fill({}),
            ],
          };
        }
        if (contact?.ims_facebook?.length < maxImLengthFacebook) {
          const facebookRest = Array(
            maxImLengthFacebook - contact?.ims_facebook?.length
          ).fill({});
          newContact = {
            ...newContact,
            ims_facebook: [...newContact?.ims_facebook, ...facebookRest],
          };
        }
        if (contact?.ims_instagram?.length < maxImLengthInstagram) {
          const instagramRest = Array(
            maxImLengthInstagram - contact?.ims_instagram?.length
          ).fill({});
          newContact = {
            ...newContact,
            ims_instagram: [...newContact?.ims_instagram, ...instagramRest],
          };
        }
        if (contact?.ims_whatsapp?.length < maxImLengthWhatsapp) {
          const whatsappRest = Array(
            maxImLengthWhatsapp - contact?.ims_whatsapp?.length
          ).fill({});
          newContact = {
            ...newContact,
            ims_whatsapp: [...newContact?.ims_whatsapp, ...whatsappRest],
          };
        }
        // if (contact?.properties?.length < maxPropertyLength) {
        //   newContact = {
        //     ...newContact,
        //     properties: [
        //       ...newContact?.properties,
        //       ...Array(maxPropertyLength - contact?.properties?.length).fill({}),
        //     ],
        //   };
        // }

        return [...acc, newContact];
      }, []);

      setContactList(newList);
    }
  }, [data.contacts, contactFields]);

  return (
    <Fade in={show}>
      <Box
        display={'flex'}
        boxShadow={1}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.dropdownBox,
          borderRadius: '4px',
          height: '100%',
        }}
        flexDirection={'column'}
        gap={2}
        pt={2}
        pb={2}
        pl={0.5}
        pr={0.5}
      >
        <Grid
          p={0.5}
          container
          spacing={2}
          sx={{
            gap: 2,
            height: 'calc(100% - 2rem)',
            marginLeft: '0',
          }}
        >
          <Box height={'100%'} overflow={'auto'} p={1}>
            <ContactCard contactInfo={null} isMerged />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: 'calc(100% - 25rem)',
              overflow: 'auto',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              boxShadow: 1,
              padding: 1,
              height: '100%',
            }}
          >
            {contactList?.map((contact) => (
              <ContactCard key={contact?.id} contactInfo={contact} />
            ))}
          </Box>
        </Grid>
        <JoinButton />
      </Box>
    </Fade>
  );
};

export default DuplicatedContactPanel;
