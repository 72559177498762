// Components/ui
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
// Types
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ActionButtonProps = {
  isLoading: boolean;
  activityId: string;
  handleActivityAction: (activityId: string) => void;
  children: ReactNode;
  title: string;
};

const ActionButton = ({
  isLoading,
  handleActivityAction,
  activityId,
  children,
  title
}: ActionButtonProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <Tooltip title={title} placement='top'>
          <IconButton onClick={() => handleActivityAction(activityId)}>
            {children}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ActionButton;
