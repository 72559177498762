import { useTranslation } from 'react-i18next';
import { memo } from 'react';
//components/ui
import { InputLabel } from '@mui/material';
import { FormControl, MenuItem, Select } from '@mui/material';
import timeZoneList from './utils/timeZoneList';

type RegionalConfigurationSelectProps = {
  value: string;
  setValue: (value: string) => void;
};

export default memo(function RegionalConfigurationSelect({
  value,
  setValue,
}: RegionalConfigurationSelectProps) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl size="small" sx={{ width: '100%', marginBottom: 2 }}>
      <InputLabel shrink>{t(`usersInfo.regionalConfigurationTimeZone`)}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        sx={{
          '& legend': {
            maxWidth: 'fit-content',
          },
        }}
        label={t(`usersInfo.regionalConfigurationTimeZone`)}
        size="small"
      >
        {timeZoneList.map((timeZone, index) => {
          return (
            <MenuItem key={index} value={timeZone.value}>
              {timeZone.description}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});
