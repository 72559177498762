import { createContext } from 'react';
// Types
import { ActivitiesCardContextType } from './types/ActivitiesCardContextType';
// Utils
import { useCreateActivityInitialDef } from '../hooks/useCreateActivity';

export const ActivitiesCardContext = createContext<ActivitiesCardContextType>({
  isCreatePopUpOpen: false,
  columns: [],
  newActivity: useCreateActivityInitialDef,
  isEditing: false,
  sortByColumn: () => {},
  openEditEventPopUp: () => {},
  closeEventPopUp: () => {},
  openCreateEventPopUp: () => {},
  handleCreateActivity: () => {},
  handleUpdateActivity: () => {},
  handleDeleteActivity: () => {},
  handleEndActivity: () => {},
  isModalDeleteEventOpen: false,
  openModalDeleteEvent: () => {},
  closeModalDeleteEvent: () => {},
  eventIdToDelete: ''
});
