import { useTranslation } from 'react-i18next';
// Components/ui
import { EditWorkspacePanel } from 'components';
// Types
import { ISpace } from '@trii/types/dist/Spaces';
import { LoadingButton } from '@mui/lab';
import { RegionalConfigurationSelect } from './components';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateWorkspace } from 'redux/features/workspace/workspaceSlice';
import { UpdateWorkspaceParam } from 'redux/features/workspace/types/UpdateWorkspaceParam';

type Props = {
  workspace: ISpace;
};

const RegionalConfiguratioPanel = ({ workspace }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [timeZone, setTimeZone] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  async function saveRegionalConfiguration() {
    setIsLoading(true);

    const updateData: UpdateWorkspaceParam = {
      id: workspace.id,
      timeZone,
    };
    await dispatch(updateWorkspace(updateData));

    setIsLoading(false);
  }

  useEffect(() => {
    setTimeZone(workspace?.timeZone);
  }, [workspace]);

  return (
    <EditWorkspacePanel title={t('usersInfo.regionalConfigurationTitle')}>
      <RegionalConfigurationSelect value={timeZone} setValue={setTimeZone} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={saveRegionalConfiguration}
        >
          {t('global.save')}
        </LoadingButton>
      </Box>
    </EditWorkspacePanel>
  );
};

export default RegionalConfiguratioPanel;
