import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import countryList from './utils/countryList';
import numberFormatList from './utils/numberFormatList';
import nameFormatList from './utils/nameFormatList';
import languageList from './utils/languageList';
import timeZoneList from './utils/timeZoneList';
import timeFormatList from './utils/timeFormatList';
import dateFormatList from './utils/dateFormatList';
import getObjectDescription from './functions/getObjectDescription';
//redux
import { useSelector } from 'react-redux';
import { selectAllProfiles } from 'redux/features/profilesSlice/profilesSlice';
import { selectAllRoles, selectRoleById } from 'redux/features/roleSlice/roleSlice';
//components/ui
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Divider from '@material-ui/core/Divider';

import { DateBox, DetailSection, InputBox, SelectBox } from './components';
// Hooks
import useField from 'hooks/useField';
import useDateField from 'hooks/useDateField';
import { useLocation } from 'react-router-dom';
// Dayjs
import dayjs from 'dayjs';
// Types
import { UserTrii } from '@trii/types/dist/Users';
import useSwitch from 'hooks/useSwitch';

interface DetailsTabProps {
  isEditing: boolean;
  user: UserTrii;
  handleUpdateUser: (user: UserTrii) => void;
  formId: string;
}

export default function DetailsTab({
  isEditing,
  user,
  handleUpdateUser,
  formId,
}: DetailsTabProps) {
  const { t } = useTranslation();
  const nameField = useField('text', user.firstname);
  const lastNameField = useField('text', user.lastname);
  const emailField = useField('text', user.email);
  const usernameField = useField('text', user.username);
  const phoneField = useField('text', user.phone);
  const birthDateField = useDateField('text', dayjs(user.birthDate));

  const sipConfigNumber = useField('text', user.sipConfigNumber);

  // SIPExtension - Custom
  const serverField = useField('text', user.sipConfigCustom?.server);
  const domainField = useField('text', user.sipConfigCustom?.sipDomain);
  const extensionField = useField('text', user.sipConfigCustom?.extension);
  const userField = useField('text', user.sipConfigCustom?.auth);
  const passwordField = useField('password', user.sipConfigCustom?.password);
  const omniLeadCheckbox = useSwitch(user.sipConfigCustom?.isOMLAccount);
  // Address
  const addressField = useField('text', user.street);
  const provinceField = useField('text', user.state);
  const localityField = useField('text', user.city);
  const countryField = useField('text', user.country);
  const facebookField = useField('text', user.socialFacebookID);
  const twitterField = useField('text', user.socialTwitterID);
  const instagramField = useField('text', user.socialInstagramID);
  const linkedinField = useField('text', user.socialLinkedinID);
  const roleField = useField('text', user.role?.title);
  const profileField = useField('text', user?.profile?.name);
  const [profileId, setProfileId] = useState(user?.profile?.id);
  const [roleId, setRoleId] = useState(user?.roleID);
  const [lenguage, setLenguage] = useState(user.regCon_language);
  const [country, setCountry] = useState(user.regCon_country);
  const [dateFormat, setDateFormat] = useState(user.regCon_dateFormat);
  const [timeFormat, setTimeFormat] = useState(user.regCon_timeFormat);
  const [timeZone, setTimeZone] = useState(user.regCon_timeZone);
  const [numberFormat, setNumberFormat] = useState(user.regCon_numberFormat);
  const [nameFormat, setNameFormat] = useState(user.nameFormat);
  const [theme, setTheme] = useState(user.theme);
  const profiles = useSelector(selectAllProfiles);
  const roles = useSelector(selectAllRoles);

  const findRole = useSelector((state) => selectRoleById(state, roleId));
  const location = useLocation();

  const NameFormatSelect = nameFormatList();
  const TimeFormatSelect = timeFormatList();

  const generalInfoSection = {
    fields: [
      {
        description: t(`usersInfo.detailsName`),
        stateHandler: nameField,
        isDisabled: false,
      },
      {
        description: t(`usersInfo.detailsLastName`),
        stateHandler: lastNameField,
        isDisabled: false,
      },
      {
        description: t(`Email`),
        stateHandler: emailField,
        isDisabled: true,
      },
      {
        description: t(`usersInfo.username`),
        stateHandler: usernameField,
        isDisabled: false,
      },

      {
        description: t(`usersInfo.detailsPhoneNumber`),
        stateHandler: phoneField,
        isDisabled: false,
      },
      {
        description: t(`usersInfo.detailsBirthDate`),
        stateHandler: birthDateField,
        isDisabled: false,
      },
    ],
    selectors: [
      {
        selectTitle: t(`usersInfo.detailsRole`),
        values: roles,
        value: roleId,
        valueName: findRole?.title,
        setValue: setRoleId,
        isDisabled: true,
        stateHandler: roleField,
      },
      {
        selectTitle: t(`usersInfo.detailsProfile`),
        values: profiles,
        value: profileId,
        valueName: user?.profile?.name,
        setValue: setProfileId,
        isDisabled: true,
        stateHandler: profileField,
      },
    ],
  };

  const addressInfoSection = [
    {
      description: t(`usersInfo.directionAddress`),
      stateHandler: addressField,
      isDisabled: false,
    },
    {
      description: t(`usersInfo.directionCity`),
      isDisabled: false,
      stateHandler: localityField,
    },
    {
      description: t(`usersInfo.directionState`),
      isDisabled: false,
      stateHandler: provinceField,
    },
    {
      description: t(`usersInfo.directionCountry`),
      isDisabled: false,
      stateHandler: countryField,
    },
  ];

  const socialMediaInfoSection = [
    {
      description: 'Facebook',
      stateHandler: facebookField,
      isDisabled: false,
    },
    {
      description: 'Instagram',
      stateHandler: twitterField,
      isDisabled: false,
    },
    {
      description: 'Linkedin',
      stateHandler: instagramField,
      isDisabled: false,
    },
    {
      description: 'Twitter',
      stateHandler: linkedinField,
      isDisabled: false,
    },
  ];

  const regionConfigSection = [
    {
      selectTitle: t(`usersInfo.regionalConfigurationLanguage`),
      values: languageList,
      value: lenguage,
      setValue: setLenguage,
    },
    {
      selectTitle: t(`usersInfo.regionalConfigurationCountry`),
      values: countryList,
      value: country,
      setValue: setCountry,
    },
    {
      selectTitle: t(`usersInfo.regionalConfigurationDateFormat`),
      values: dateFormatList,
      value: dateFormat,
      setValue: setDateFormat,
    },
    {
      selectTitle: t(`usersInfo.regionalConfigurationTimeFormat`),
      values: TimeFormatSelect,
      value: timeFormat,
      setValue: setTimeFormat,
    },
    {
      selectTitle: t(`usersInfo.regionalConfigurationTimeZone`),
      values: timeZoneList,
      value: timeZone,
      setValue: setTimeZone,
    },
    {
      selectTitle: t(`usersInfo.regionalConfigurationNumberFormat`),
      values: numberFormatList,
      value: numberFormat,
      setValue: setNumberFormat,
    },
  ];

  const sipConfigNumberSection = [
    {
      description: t(`sipNumber`),
      stateHandler: sipConfigNumber,
      isDisabled: false,
    },
  ];

  const sipExtensionSection = [
    {
      description: t(`usersInfo.server`),
      stateHandler: serverField,
      isDisabled: false,
    },
    {
      description: t(`usersInfo.domain`),
      stateHandler: domainField,
      isDisabled: false,
    },
    {
      description: t(`Extension`),
      stateHandler: extensionField,
      isDisabled: false,
    },
    {
      description: t(`User / auth`),
      stateHandler: userField,
      isDisabled: false,
    },
    {
      description: t(`global.password`),
      stateHandler: passwordField,
      isDisabled: false,
    },
  ];

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { value: firstname } = nameField.attributes;
    const { value: email } = emailField.attributes;
    const name = firstname ? firstname : email;
    const updatedTriiUser = {
      uid: user.uid,
      firstname: name,
      lastname: lastNameField.attributes.value,
      email: email,
      username: usernameField.attributes.value,
      roleID: roleId,
      profileID: profileId,
      phone: phoneField.attributes.value,
      birthDate: birthDateField.attributes.value,
      sipConfigNumber: sipConfigNumber.attributes.value,
      sipConfigCustom: {
        auth: userField.attributes.value,
        password: passwordField.attributes.value,
        extension: extensionField.attributes.value,
        server: serverField.attributes.value,
        sipDomain: domainField.attributes.value,
        isOMLAccount: omniLeadCheckbox.checked,
      },
      street: addressField.attributes.value,
      city: localityField.attributes.value,
      state: provinceField.attributes.value,
      country: countryField.attributes.value,
      socialFacebookID: facebookField.attributes.value,
      socialTwitterID: twitterField.attributes.value,
      socialInstagramID: instagramField.attributes.value,
      socialLinkedinID: linkedinField.attributes.value,
      regCon_language: lenguage,
      regCon_country: country,
      regCon_dateFormat: dateFormat,
      regCon_timeFormat: timeFormat,
      regCon_timeZone: timeZone,
      regCon_numberFormat: numberFormat,
      nameFormat: nameFormat,
      theme: theme,
    };
    // @ts-ignore
    await handleUpdateUser(updatedTriiUser);
  };

  useEffect(() => {
    setRoleId(user?.roleID);
    setProfileId(user?.profileID);
  }, [user]);

  // const translateNameFormatList = (nameFormatList, t) => {
  //   return nameFormatList.map(item => ({
  //     ...item,
  //     description: t(`user.${item.description}`),
  //   }));
  // };
  // const translatedNameFormatList = translateNameFormatList(nameFormatList, t);

  return (
    <Box display={'flex'} flexDirection="column" height={'100%'} px={5} py={4}>
      <form
        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        id={formId}
        onSubmit={handleFormSubmit}
      >
        <DetailSection sectionTitle={'General'}>
          {generalInfoSection.fields.map((e, i) =>
            e.description === t(`usersInfo.detailsBirthDate`) ? (
              <DateBox
                key={i}
                boxDescription={e.description}
                stateHandler={e.stateHandler}
                isDisabled={e.isDisabled}
                isEditing={isEditing}
              />
            ) : (
              <InputBox
                key={i}
                boxDescription={e.description}
                stateHandler={e.stateHandler}
                isDisabled={e.isDisabled}
                isEditing={isEditing}
              />
            )
          )}
          {generalInfoSection.selectors.map((box, index) =>
            location.pathname === '/a/settings/profile' ? (
              <InputBox
                key={index}
                boxDescription={box.selectTitle}
                stateHandler={box.stateHandler}
                isDisabled={box.isDisabled}
                isEditing={isEditing}
              />
            ) : (
              <SelectBox
                valueName={box.valueName}
                key={index}
                selectTitle={box.selectTitle}
                values={box.values}
                value={box.value}
                setValue={box.setValue}
                isEditing={isEditing}
              />
            )
          )}
        </DetailSection>
        {location.pathname !== '/a/settings/profile' && (
          <DetailSection sectionTitle={t(`usersInfo.sipNumber`)}>
            {sipConfigNumberSection.map((box, index) => (
              <InputBox
                key={index}
                boxDescription={box.description}
                stateHandler={box.stateHandler}
                isDisabled={box.isDisabled}
                isEditing={isEditing}
              />
            ))}
          </DetailSection>
        )}
        {location.pathname !== '/a/settings/profile' && (
          <DetailSection sectionTitle={t(`usersInfo.sipExtension`)}>
            {sipExtensionSection.map((box, index) => (
              <InputBox
                key={index}
                boxDescription={box.description}
                stateHandler={box.stateHandler}
                isDisabled={box.isDisabled}
                isEditing={isEditing}
              />
            ))}
            <Box display="flex" mt={2} gap={2} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    {...omniLeadCheckbox}
                    disabled={!isEditing}
                    color="primary"
                  />
                }
                labelPlacement="start"
                label={
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: 'text.primary',
                      '&.Mui-disabled': { color: 'text.primary' },
                    }}
                    variant="subtitle2"
                  >
                    OmniLead
                  </Typography>
                }
                sx={{
                  marginLeft: '10px',
                }}
              />
              <Typography>{t('usersInfo.omniLeadDescription')}</Typography>
            </Box>
          </DetailSection>
        )}
        <Divider />
        <DetailSection sectionTitle={t(`usersInfo.directionTitle`)}>
          {addressInfoSection.map((box, index) => (
            <InputBox
              key={index}
              boxDescription={box.description}
              stateHandler={box.stateHandler}
              isDisabled={box.isDisabled}
              isEditing={isEditing}
            />
          ))}
        </DetailSection>
        <Divider />
        <DetailSection sectionTitle={t(`usersInfo.socialMedia`)}>
          {socialMediaInfoSection.map((box, index) => (
            <InputBox
              key={index}
              boxDescription={box.description}
              stateHandler={box.stateHandler}
              isDisabled={box.isDisabled}
              isEditing={isEditing}
            />
          ))}
        </DetailSection>
        <Divider />
        <DetailSection sectionTitle={t(`usersInfo.regionalConfigurationTitle`)}>
          {regionConfigSection.map((box, index) => {
            return (
              <SelectBox
                valueDescription={getObjectDescription(box.values, box.value)}
                key={index}
                selectTitle={box.selectTitle}
                values={box.values}
                value={box.value}
                setValue={box.setValue}
                isEditing={isEditing}
              />
            );
          })}
        </DetailSection>
        <Divider />
        <DetailSection sectionTitle={t(`usersInfo.otherSettingsTitle`)}>
          <SelectBox
            selectTitle={t(`usersInfo.otherSettingsNameFormat`)}
            values={NameFormatSelect}
            value={nameFormat}
            setValue={setNameFormat}
            valueDescription={getObjectDescription(NameFormatSelect, nameFormat)}
            isEditing={isEditing}
          />
          {/* <ColorBox theme={theme} setTheme={setTheme} isEditing={isEditing} /> */}
        </DetailSection>
      </form>
    </Box>
  );
}
