import { memo, useContext } from 'react';
// Context
import { ActivitiesCardContext } from '../../../../../../context/ActivitiesCardContext';
// Redux
import { useSelector } from 'react-redux';
import {
  selectActivitiesDeleteStatus,
  selectActivitiesUpdateEndingStatus,
} from 'ReduxToolkit/features/activitiesSlice/utils/selectors';
// Date
import moment from 'moment/moment';
// Components/ui
import { StyledTableRow, StyledTableCell, StyledTypography } from './styles';
import { ActionButton } from './components';
import { Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
// Types
import { IEvent } from '@trii/types/dist/Calendar';
import { useTranslation } from 'react-i18next';
interface ActivitiesRowProps {
  activityData: IEvent;
}

const ActivitiesRow = memo(({ activityData }: ActivitiesRowProps) => {
  const { t } = useTranslation();
  const { handleDeleteActivity, handleEndActivity, openEditEventPopUp, openModalDeleteEvent } =
    useContext(ActivitiesCardContext);
  const activitiesDeleteStatus = useSelector(selectActivitiesDeleteStatus);
  const activitiesEndingStatus = useSelector(
    selectActivitiesUpdateEndingStatus
  );
  const isDeleteLoading = activitiesDeleteStatus.includes(activityData.id);
  const isEndingLoading = activitiesEndingStatus.includes(activityData.id);
  
  return (
    <StyledTableRow>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {activityData.title}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {moment(activityData.startAt).format('DD/MM/YYYY')}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {moment(activityData.startAt).format('HH:mm ')}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {activityData.duration}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {activityData.createdBy.name}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {/* {activityData.createdBy.name} */}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActionButton
            activityId={activityData.id}
            handleActivityAction={handleEndActivity}
            isLoading={isEndingLoading}
            title={t('contactDetails.finalizeEvent')}
          >
            <EventBusyIcon />
          </ActionButton>
          <Tooltip title={t('contactDetails.editEvent')} placement='top'>
            <IconButton onClick={() => openEditEventPopUp(activityData)}>
              <EditCalendarIcon />
            </IconButton>
          </Tooltip>
          <ActionButton
            activityId={activityData.id}
            handleActivityAction={() => openModalDeleteEvent(activityData.id)}
            isLoading={isDeleteLoading}
            title={t('contactDetails.deleteEvent')}
          >
            <DeleteIcon />
          </ActionButton>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
});

export default ActivitiesRow;
