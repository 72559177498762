import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
// Components/ui
import { Box, Divider } from '@mui/material';
import {
  Address,
  BirthDate,
  Business,
  CardHeader,
  ContactList,
  Header,
  Labels,
  Properties,
} from './components';
import {
  CalendarMonth,
  Domain,
  Email,
  Facebook,
  Instagram,
  PhoneEnabled,
  WhatsApp,
} from '@mui/icons-material';
// Styles
import { StyledContactDataContainer } from './styles';
// Types
import { IContact, IContactAddress } from '@trii/types/dist/Contacts';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

interface Props {
  contactInfo: IContact;
  isMerged?: boolean;
}

const ContactCard = ({ contactInfo, isMerged = false }: Props) => {
  const {
    address1,
    address2,
    contactsId,
    emails,
    ims_facebook,
    ims_instagram,
    ims_whatsapp,
    phones,
    handleSelectContactAddress,
    setEmails,
    setContactsId,
    setIms_facebook,
    setIms_instagram,
    setIms_whatsapp,
    setPhones,
    setTriggerSelectAllId,
  } = useContext(DuplicateContext);

  const iconStyle = { color: (theme) => theme.palette.primary.dark };
  const [imgSelected, setImgSelected] = useState<boolean>(false);
  const [nameSelected, setNameSelected] = useState<boolean>(false);
  const [tagsSelected, setTagsSelected] = useState<boolean>(false);
  const [birthDateSelected, setBirthDateSelected] = useState<boolean>(false);
  const [address1Selected, setAddress1Selected] = useState<boolean>(false);
  const [address2Selected, setAddress2Selected] = useState<boolean>(false);
  const [businessSelected, setBusinessSelected] = useState<boolean>(false);
  const [phoneSelected, setPhoneSelected] = useState<boolean>(false);
  const [emailSelected, setEmailSelected] = useState<boolean>(false);
  const [imSelected, setImSelected] = useState<boolean>(false);
  const [propertySelected, setPropertySelected] = useState<boolean>(false);

  const handleSelectAll = () => {
    setTriggerSelectAllId(contactInfo?.id);
  };

  const handleSelect =
    (setState: Dispatch<SetStateAction<IContactAddress[]>>) =>
    (value: IContactAddress) => {
      handleSelectContactAddress(setState, value, contactInfo?.id);
    };

  useEffect(() => {
    if (
      (imgSelected ||
        nameSelected ||
        tagsSelected ||
        birthDateSelected ||
        businessSelected ||
        address1Selected ||
        address2Selected ||
        phoneSelected ||
        emailSelected ||
        imSelected ||
        propertySelected) &&
      !contactsId.includes(contactInfo?.id)
    ) {
      setContactsId([...contactsId, contactInfo?.id]);
    } else {
      if (
        !imgSelected &&
        !nameSelected &&
        !tagsSelected &&
        !birthDateSelected &&
        !address1Selected &&
        !address2Selected &&
        !businessSelected &&
        !phoneSelected &&
        !emailSelected &&
        !imSelected &&
        !propertySelected &&
        contactsId.includes(contactInfo?.id)
      ) {
        setContactsId(contactsId.filter((id) => id !== contactInfo?.id));
      }
    }
  }, [
    imgSelected,
    nameSelected,
    tagsSelected,
    birthDateSelected,
    address1Selected,
    address2Selected,
    businessSelected,
    phoneSelected,
    emailSelected,
    imSelected,
    propertySelected,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        p: 1,
        borderRadius: 1,
        boxShadow: (theme) =>
          isMerged ? `0px 0px 5px ${theme.palette.primary.main}` : 1,
        height: 'max-content',
      }}
    >
      <CardHeader
        isMerged={isMerged}
        handleSelectAll={handleSelectAll}
        contactId={contactInfo?.id}
      />
      <StyledContactDataContainer>
        <Header
          contactInfo={contactInfo}
          isMerged={isMerged}
          setImgSelected={setImgSelected}
          setNameSelected={setNameSelected}
        />
        {/* Labels */}
        <Labels
          labels={contactInfo?.tags}
          contactId={contactInfo?.id}
          setTagsSelected={setTagsSelected}
          isMerged={isMerged}
        />
        <Divider variant="middle" />
        {/* Address */}
        <Address
          address={!isMerged ? contactInfo?.address1 : address1}
          contactId={contactInfo?.id}
          setAddressSelected={setAddress1Selected}
          isMerged={isMerged}
        />
        <Address
          address={!isMerged ? contactInfo?.address2 : address2}
          setAddressSelected={setAddress2Selected}
          contactId={contactInfo?.id}
          showTitle={false}
          isMerged={isMerged}
        />
        {/* BirthDate */}
        <BirthDate
          icon={<CalendarMonth fontSize="small" sx={iconStyle} />}
          dateContact={contactInfo?.birthDate}
          contactId={contactInfo?.id}
          isMerged={isMerged}
          setBirthDateSelected={setBirthDateSelected}
        />
        {/* Business */}
        <Business
          businessData={{
            name: contactInfo?.businessName,
            id: contactInfo?.businessId,
          }}
          contactId={contactInfo?.id}
          isMerged={isMerged}
          setBusinessSelected={setBusinessSelected}
          icon={<Domain fontSize="small" sx={iconStyle} />}
        />
        {/* Phone */}
        <ContactList
          contactId={contactInfo?.id}
          icon={<PhoneEnabled fontSize="small" sx={iconStyle} />}
          title="duplicatesView.contactInfo.phone"
          contactAddresses={contactInfo?.phones || []}
          isMerged={isMerged}
          handleSelect={handleSelect(setPhones)}
          setSelected={setPhoneSelected}
          mergedData={phones}
        />
        {/* Email */}
        <ContactList
          contactId={contactInfo?.id}
          icon={<Email fontSize="small" sx={iconStyle} />}
          title="duplicatesView.contactInfo.email"
          contactAddresses={contactInfo?.emails || []}
          showCampaign
          isMerged={isMerged}
          handleSelect={handleSelect(setEmails)}
          setSelected={setEmailSelected}
          mergedData={emails}
        />
        {/* WhatsApp */}
        <ContactList
          contactId={contactInfo?.id}
          icon={<WhatsApp fontSize="small" sx={iconStyle} />}
          title="WhatsApp"
          contactAddresses={contactInfo?.ims_whatsapp || []}
          showCampaign
          isMerged={isMerged}
          handleSelect={handleSelect(setIms_whatsapp)}
          setSelected={setImSelected}
          mergedData={ims_whatsapp}
        />
        {/* Facebook */}
        <ContactList
          contactId={contactInfo?.id}
          icon={<Facebook fontSize="small" sx={iconStyle} />}
          title="Facebook"
          contactAddresses={contactInfo?.ims_facebook || []}
          showCampaign
          isMerged={isMerged}
          handleSelect={handleSelect(setIms_facebook)}
          setSelected={setImSelected}
          mergedData={ims_facebook}
        />
        {/* Instagram */}
        <ContactList
          contactId={contactInfo?.id}
          icon={<Instagram fontSize="small" sx={iconStyle} />}
          title="Instagram"
          contactAddresses={contactInfo?.ims_instagram || []}
          showCampaign
          isMerged={isMerged}
          handleSelect={handleSelect(setIms_instagram)}
          setSelected={setImSelected}
          mergedData={ims_instagram}
        />
        {/* Properties */}
        <Properties
          newProperties={contactInfo?.properties}
          contactId={contactInfo?.id}
          setPropertySelected={setPropertySelected}
          isMerged={isMerged}
        />
      </StyledContactDataContainer>
    </Box>
  );
};

export default ContactCard;
