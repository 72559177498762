import { memo, useEffect, useState } from 'react';
// Context
import { ActivitiesCardContext } from './ActivitiesCardContext';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  addActivity,
  updateActivity,
  deleteActivity,
  endActivity,
  fetchActivities,
  removeDeletingActivityStatus,
  removeEndingActivityStatus,
  setDeletingActivityStatus,
  setEndingActivityStatus,
  sortActivitiesByColumn,
} from 'ReduxToolkit/features/activitiesSlice/activitiesSlice';
// Types
import { FC, ReactNode } from 'react';
import { ActivitiesCardContextType } from './types/ActivitiesCardContextType';
import { ActivitiesTableColumn } from './types/ActivitiesTableColumn';
import { ActivitiesTableColumnName } from 'ReduxToolkit/features/activitiesSlice/types/ActivitiesTableColumnName';
import { IEvent } from '@trii/types/dist/Calendar';
// Utils
import { activitiesTableColumns } from './utils/columns';
import { getOrderedColumns } from '../../../utils/getOrderedColumns';
import { useCreateActivity } from '../hooks/useCreateActivity';

const ActivitiesCardContextProvider: FC<{ children: ReactNode }> = memo(
  ({ children }) => {
    const dispatch = useAppDispatch();
    const [isCreatePopUpOpen, setIsCreatePopUpOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [columns, setColumns] = useState<ActivitiesTableColumn[]>(
      activitiesTableColumns
    );
    const [selectedActivity, setSelectedActivity] = useState<IEvent>();
    const newActivity = useCreateActivity(selectedActivity);
    const [isModalDeleteEventOpen, setIsModalDeleteEventOpen] = useState(false);
    const [eventIdToDelete, setEventIdToDelete] = useState<string | null>(null);
    function openEditEventPopUp(event: IEvent) {
      setSelectedActivity(event);
      setIsEditing(true);
      setIsCreatePopUpOpen(true);
    }

    function openCreateEventPopUp() {
      setIsEditing(false);
      setIsCreatePopUpOpen(true);
    }

    function closeEventPopUp() {
      newActivity.action.resetFields();
      setIsCreatePopUpOpen(false);
      setIsEditing(false);
      setIsEditing(false);
      setSelectedActivity(null);
    }
    const openModalDeleteEvent = (activityId: string) => {
      setEventIdToDelete(activityId);
      setIsModalDeleteEventOpen(true);
    };
  
    const closeModalDeleteEvent = () => {
      setEventIdToDelete(null);
      setIsModalDeleteEventOpen(false);
    };
    function sortByColumn(
      columnName: ActivitiesTableColumnName,
      sortOrder: 'ASC' | 'DESC'
    ) {
      const newColumns: ActivitiesTableColumn[] = getOrderedColumns(
        columns,
        columnName,
        sortOrder
      );

      setColumns(newColumns);

      dispatch(sortActivitiesByColumn({ columnName, sortOrder }));
    }

    async function handleCreateActivity() {
      const activity = newActivity.action.getActivity();
      await dispatch(addActivity(activity));
      closeEventPopUp();
    }

    async function handleDeleteActivity(activityId: string) {
      const idToDelete = activityId || eventIdToDelete;
      if (idToDelete) {
        dispatch(setDeletingActivityStatus(activityId));
        await dispatch(deleteActivity(activityId));
        dispatch(removeDeletingActivityStatus(activityId));
        closeModalDeleteEvent();
      }
    }

    async function handleEndActivity(activityId: string) {
      dispatch(setEndingActivityStatus(activityId));
      await dispatch(endActivity(activityId));
      dispatch(removeEndingActivityStatus(activityId));
    }
    async function handleUpdateActivity() {
      const activity = newActivity.action.getActivity();
      await dispatch(
        updateActivity({
          updatedActivity: activity,
          activityId: selectedActivity.id,
        })
      );
      closeEventPopUp();
    }

    const value: ActivitiesCardContextType = {
      sortByColumn,
      columns,
      openCreateEventPopUp,
      closeEventPopUp,
      openEditEventPopUp,
      isCreatePopUpOpen,
      newActivity,
      handleCreateActivity,
      handleUpdateActivity,
      handleDeleteActivity,
      handleEndActivity,
      isEditing,
      openModalDeleteEvent,
      closeModalDeleteEvent,
      isModalDeleteEventOpen,
      eventIdToDelete,
    };

    useEffect(() => {
      dispatch(fetchActivities());
    }, []);

    return (
      <ActivitiesCardContext.Provider value={value}>
        {children}
      </ActivitiesCardContext.Provider>
    );
  }
);

export default ActivitiesCardContextProvider;
